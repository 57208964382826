import { abortNavigation, defineNuxtRouteMiddleware, useNuxtApp } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';

import { showAlert } from '@/composables/useDialog';
import { BUSINESS_BANK_STATUS } from '@/constants/business-bank.const';
import { MODE } from '@/constants/common.const';
import { MENU_ACCESS_DENY_ERROR_CODE } from '@/constants/error.const';
import { ERROR_PAGE_NAME } from '@/constants/url.const';
import { useAppStore } from '@/stores/app.store';
import { useBusinessBankStore } from '@/stores/business-bank.store';
import { isDirectAccess, redirectTo } from '@/utils/common.util';

export default defineNuxtRouteMiddleware(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { name: toName, meta, params } = to;
    const { name: fromName } = from;
    const { mode } = params;

    const businessBankStore = useBusinessBankStore();
    const { bankStatus } = storeToRefs(businessBankStore);
    await businessBankStore.fetchBankInfos();
    const isUnderReview = bankStatus.value === BUSINESS_BANK_STATUS.REQUEST;

    const { isMenuWritable } = storeToRefs(useAppStore());
    if (
      isUnderReview ||
      isMenuWritable.value ||
      (meta?.editModeAccessible && mode !== MODE.CREATE)
    ) {
      return;
    }

    const msg = 'studio.common.popup_case_h_not_edit';

    const nuxtApp = useNuxtApp();
    const t = (nuxtApp.$i18n as any)?.t;
    if (isDirectAccess(toName, fromName)) {
      return await redirectTo(ERROR_PAGE_NAME, {
        toType: 'name',
        query: { code: MENU_ACCESS_DENY_ERROR_CODE }
      });
    }
    await showAlert({ content: t(msg) });
    return abortNavigation();
  }
);
